import OrganizationEventTags from "../../../components/Settings/OrganizationEventTags";

const AnalyticsTab = () => {
  return (<>
    <OrganizationEventTags/>
  </>
  );
};

export default AnalyticsTab;
