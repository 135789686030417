import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { 
  AbstractEventAnalyticsLabel, AnalyticsEventColor,
  Calendar, FetchReturn, RootState, ThunkDispatchType
} from '../../../store';
import { 
  createAnalyticsEventCategory, createAnalyticsEventType, deleteAnalyticsEventCategory, 
  deleteAnalyticsEventType, updateAnalyticsEventCategory, updateAnalyticsEventType,
  createAnalyticsExecutiveContactCategory, updateAnalyticsExecutiveContactCategory, 
  deleteAnalyticsExecutiveContactCategory,
  fetchAnalyticsEventColor,
  updateAnalyticsEventColor
} from '../../../store/eventAnalytics/actions';
import OrganizationEventTags from './OrganizationEventTags';
import {
  AutoAnalyticsEventCategoryLabels
} from '../../EventAnalytics/AutoAnalyticsEventCategoryLabels/AutoAnalyticsEventCategoryLabels';
import { Box, Typography, styled } from '@mui/material';
import colors from '../../../colors';
import { fetchRemoteCalendars } from '../../../store/schedule/actions';
import { GridSortModel, GridFilterModel, GridCallbackDetails } from '@mui/x-data-grid-pro';
import { PermissionError } from '../../../utils/permissionUtils';


const StyledBox = styled(Box, {label: "StyledBox"})(() => ({
  width: '100%',
  marginTop: 8,
  border: '1px solid',
  borderColor: colors.black200,
  borderRadius: '4px',
}));

const OrganizationEventTagsContainer = (): ReactElement => {

  const eventCategories = useSelector((root: RootState) => root.eventAnalytics.event_categories);
  const eventTypes = useSelector((root: RootState) => root.eventAnalytics.event_types);
  const executiveContactCategories = useSelector((root: RootState) => root.eventAnalytics.executive_contact_categories);
  const analyticsColors = useSelector((root: RootState) => root.eventAnalytics.analytics_colors);
  const calendars = useSelector((root: RootState) => root.schedule.calendars);
  const user = useSelector((root: RootState) => root.auth.user);

  const [saving, setSaving] = useState(false);
  const [colorGridFilterParameters, setColorGridFilterParameters] = useState<
    Record<string, string | string[] | undefined | number | number[]>
  >({order_by: "calendar"});
  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    dispatch(fetchRemoteCalendars());
  }, [dispatch]);

  const createLabel = async (
    action: "event_type" | "event_category" | "contact_category", 
    data: AbstractEventAnalyticsLabel,
    callback: () => void
  ): Promise<FetchReturn<AbstractEventAnalyticsLabel, PermissionError> | undefined> => {
    setSaving(true);
    let res: FetchReturn<AbstractEventAnalyticsLabel, PermissionError> | undefined = undefined;
    if (action === "event_category") {
      res = await dispatch(createAnalyticsEventCategory(data)).then(createCategoryRes => {
        if (createCategoryRes.status === 201) {
          callback();
        }
        return createCategoryRes;
      });
    } else if (action === "event_type") {
      res = await dispatch(createAnalyticsEventType(data)).then(createTypeRes => {
        if (createTypeRes.status === 201) {
          callback();
        }
        return createTypeRes;
      });
    } else if (action === "contact_category") {
      res = await dispatch(createAnalyticsExecutiveContactCategory(data)).then(createContactCategoryRes => {
        if (createContactCategoryRes.status === 201) {
          callback();
        }
        return createContactCategoryRes;
      });
    }
    setSaving(false);
    return res;
  };

  const updateLabel = async (
    action: "event_type" | "event_category" | "contact_category", 
    data: Partial<AbstractEventAnalyticsLabel> & Pick<AbstractEventAnalyticsLabel, 'id'>, callback: () => void
  ): Promise<FetchReturn<AbstractEventAnalyticsLabel, PermissionError> | undefined> => {
    setSaving(true);
    let res: FetchReturn<AbstractEventAnalyticsLabel, PermissionError> | undefined = undefined;
    if (action === "event_category") {
      res = await dispatch(updateAnalyticsEventCategory(data)).then(updateCategoryRes => {
        if (updateCategoryRes.status === 200) {
          callback();
        }
        return res;
      });
    } else if (action === "event_type") {
      res = await dispatch(updateAnalyticsEventType(data)).then(updateTypeRes => {
        if (updateTypeRes.status === 200) {
          callback();
        }
        return res;
      });
    } else if (action === "contact_category") {
      res = await dispatch(updateAnalyticsExecutiveContactCategory(data)).then(updateContactCategoryRes => {
        if (updateContactCategoryRes.status === 200) {
          callback();
        }
        return res;
      });
    }
    setSaving(false);
    return res;
  };

  const deleteLabel = (action: "event_type" | "event_category" | "contact_category",
    id: number, callback: () => void) => {
    setSaving(true);
    if (action === "event_category") {
      dispatch(deleteAnalyticsEventCategory(id)).then(res => {
        if (res.status === 204) {
          callback();
          setSaving(false);
        }
      });
    } else if (action === "event_type") {
      dispatch(deleteAnalyticsEventType(id)).then(res => {
        if (res.status === 204) {
          callback();
          setSaving(false);
        }
      });
    } else if (action === "contact_category") {
      dispatch(deleteAnalyticsExecutiveContactCategory(id)).then(res => {
        if (res.status === 204) {
          callback();
          setSaving(false);
        }
      });
    }
  };

  const updateAnalyticsColor = (color: AnalyticsEventColor) => {
    dispatch(updateAnalyticsEventColor(color));
  };

  useEffect(() => {
    dispatch(fetchAnalyticsEventColor(colorGridFilterParameters));
  }, [dispatch, colorGridFilterParameters]);

  const calendarsObject = useMemo(() => {
    const newCalendarsObject: {[key: string]: Calendar} = {};
    calendars.forEach(cal => {
      newCalendarsObject[cal.id] = cal;
    });
    return newCalendarsObject;
  }, [calendars]);
  
  const handleSortModelChange = useCallback((sortModel: GridSortModel) => {
    const order_by: string[] = [];
    sortModel.forEach((sort) => {
      order_by.push(`${sort["sort"] !== "asc" ? "-" : ""}${sort["field"]}`);
    });
    const updateFilters = {...colorGridFilterParameters};
    updateFilters["order_by"] = order_by;
    setColorGridFilterParameters(updateFilters);
  }, [colorGridFilterParameters, setColorGridFilterParameters]);

  const handleFilterModelChange = useCallback((model: GridFilterModel, details: GridCallbackDetails) => {
    const order_by = colorGridFilterParameters["order_by"];
    const filters: Record<string, string | string[] | undefined | number | number[]> = {};

    model.items.forEach(item => {
      if (item.value) {
        if (item["operator"] !== "eq") {
          filters[`${item["field"]}__${item["operator"]}`] = item["value"];
        } else {
          filters[item["field"]] = item["value"];
        }
      }
    });

    filters["topology"] = model?.logicOperator || "and";
    if (order_by) {
      filters["order_by"] = order_by;
    }
    setColorGridFilterParameters(filters);
  }, [colorGridFilterParameters, setColorGridFilterParameters]);
  
  return (<>
    <Typography variant="h2"
      sx={{
        marginTop: 2,
        marginBottom: 2,
        fontSize: "18px"
      }}
    >
      Label Management
    </Typography>
    <StyledBox>
      
      <OrganizationEventTags
        eventCategories={eventCategories}
        eventTypes={eventTypes}
        executiveContactCategories={executiveContactCategories}
        user={user}
        createLabel={createLabel}
        updateLabel={updateLabel}
        saving={saving}
        deleteLabel={deleteLabel}
      />
    </StyledBox>
    
    <Box marginTop={6}>
      <Typography variant="h2"
        sx={{ 
          marginTop: 2,
          marginBottom: 0,
          fontSize: "18px"
        }}
      >
        Auto-Assign from Calendar Colors
      </Typography>
      <AutoAnalyticsEventCategoryLabels
        analyticsColors={analyticsColors}
        eventCategories={eventCategories}
        eventTypes={eventTypes}
        calendars={calendarsObject}
        updateAnalyticColor={updateAnalyticsColor}
        handleSortModelChange={handleSortModelChange}
        handleFilterModelChange={handleFilterModelChange}
      />
    </Box>
  </>
  );
};

export default OrganizationEventTagsContainer;